 .unit-section {
 	margin-bottom: var(--unit-section-mb);
 }

 .unit-map {
 	width: 100%;
 	height: var(--unit-map-height);
 	position: relative;
 }


 @media (max-width: 992px) {
 	.unit-view {
 		.owl-carousel-unit {
 			.img-thumbnail {
 				height: 500px;
 				object-fit: cover;
 			}
 		}
 	}
 }

 @media (max-width: 767px) {
 	.unit-view {
 		.owl-carousel-unit {
 			.img-thumbnail {
 				height: 350px;
 				object-fit: cover;
 			}
 		}
 	}
 }