.impressum-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/impressum-banner.jpg);
	}

	.first-row {
		@media (max-width: 767px) {
			flex-direction: column-reverse;
		}

		.right {
			text-align: right;
			margin-bottom: 30px;

			@media (max-width: 767px) {
				text-align: left;
			}

			img {
				max-width: 200px;
				width: 100%;
			}
		}
	}

}