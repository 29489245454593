.impressionen-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/impressionen-banner.jpg);
	}

	.blogs-img {
		img {
			margin-bottom: 30px;

			&.img-responsive {
				max-width: 100%;
			}
		}
	}
}