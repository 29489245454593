.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);

	@media (max-height: 600px) {
		min-height: 680px;

	}



	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);

			@media (max-height: 600px) {
				min-height: 680px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 30vh;
		font-family: 'Poppins', cursive;
		width: 100%;
		z-index: 3;
		padding: 0;

		@media all and (min-width: 576px) and (max-width: 767px) {
			top: 24vh;
		}

		@media (max-width: 767px) {
			top: 30vh;
		}

		@media (max-height: 600px) {
			top: 200px;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			top: 37vh;
		}

		@media all and (min-width: 992px) and (max-width: 1199px) {
			top: 38vh;
		}

		.hero-text {
			text-align: center;
			background-color: rgba(0, 0, 0, .15);
			padding: 15px 25px;
			margin: 0 auto;
			display: block;

			h1,
			h2 {
				font-family: var(--hero-header-font-family);
				font-size: 70px;
				color: #fff;
				font-weight: 400;
				margin-bottom: 0px;

				text-shadow: 0 0 3px #333;

				@media (min-width: 1141px) {
					font-size: 50px;
				}

				@media (min-width: 1600px) {
					font-size: 70px;
				}

				@media (max-width: 1140px) {
					font-size: 50px;
					line-height: 60px;
				}

				@media (max-width: 992px) {
					font-size: 40px;
					line-height: 52px;
				}

				@media (max-width: 767px) {
					font-size: 30px;
					line-height: 42px;
				}

				@media (max-width: 576px) {
					font-size: 24px;
					line-height: 38px;
				}

				@media (max-height: 600px) {
					font-size: 20px;
					line-height: 32px;
				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 30px;
				color: #fff;

				text-shadow: 0 0 3px #333;


				margin: 0;

				@media (min-width: 1600px) {
					font-size: 30px;
				}

				@media (max-width: 1199px) {
					font-size: 24px;
				}

				@media (max-width: 992px) {
					font-size: 22px;
				}

				@media (max-width: 767px) {
					font-size: 18px;
				}

				@media (max-width: 575px) {
					font-size: 15px;
				}

				@media (max-height: 600px) {
					font-size: 15px;
				}
			}
		}

	}
}