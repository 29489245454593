.unit-feedbacks-v1 {
	.ptitle {
		display: inline-block;
	}

	.ratings {
		display: flex;
		flex-wrap: wrap;

		.rating {
			margin: 0 30px 15px 0;

			.fa {
				color: var(--color-primary)
			}
		}
	}

	.stars {
		display: flex;
		align-items: center;

		.rating-btn {
			padding: 5px 10px 8px;
			color: #fff;
			border-radius: 5px;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			font-weight: 500;
			line-height: 25px;
			font-size: 1rem;
			font-size: var(--font-size-md);
			background: #ffbd04;
			background: var(--color-secondary);
			display: inline-block;
		}

		.stars-bl {
			background-color: var(--color-white);
			padding: 6px 9px;
			border-radius: 5px;
			box-shadow: 0 0px 5px rgba(0, 0, 0, .15);
			margin-left: 12px;
		}

	}

	.feedbacks {

		clear: left;
		padding-top: 0px;

		.feedback {
			border-left: 3px solid var(--color-grey-normal);
			background-color: var(--color-grey-light);
			padding: 10px;
			font-style: italic;
			margin-bottom: 10px;

			.rating {
				font-size: var(--font-size-sm);
			}

			.guest-text {
				clear: left;
				padding-top: 10px;
				font-size: var(--font-size-md);
			}

			.author {
				font-size: var(--font-size-sm);
			}

			.comment {
				padding-left: 10px;
				border-left: 3px dotted var(--color-red);
				font-size: var(--font-size-md);
			}

			.agency {
				padding-bottom: 10px;
			}
		}
	}
}