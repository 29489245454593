.footer-v1 {

	.main-footer {

		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 14%, rgba(165, 218, 255, 0.44) 99%);
		background-color: #e0ebf2;
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}

		.second-col,
		.third-col {
			ul {
				margin: 0;

				li {
					margin: 0 0 15px;
					position: relative;
					padding: 0 0 0 30px;

					.fa {
						position: absolute;
						left: 0;
						top: 4px;
					}
				}

			}

		}


		.logo {
			width: auto;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
			max-width: 100%;
		}

		.social-icons {
			width: 100%;
			max-width: 165px;
			text-align: center;
		}

		.openings {
			font-style: italic;
			position: relative;
			padding: 0 0 0 30px;

			.fa {
				position: absolute;
				left: 0;
				top: 4px;
			}

			div {
				padding-top: 0;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 24px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #e58400;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);

		p {
			@media (max-width: 767px) {
				text-align: center;
			}
		}

		.v-logo-col {
			text-align: right;

			@media (max-width: 767px) {
				text-align: center;
			}

			.v-logo {
				background: #fff;
				padding: 5px;
				border-radius: 5px;
				width: 110px;
			}
		}
	}

}