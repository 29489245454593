.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}

	&.print-col {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}