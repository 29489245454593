.immobilien-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/immobilien-banner.jpg);
	}
}

.heiligenhafen-v1 {
	margin-top: var(--page-margin-top);


	.inner-banner {
		background-image: url(RESOURCE/img/banner-heiligenhafen.jpeg);
		background-position: 0 -240px;

		@media (max-width:1600px) {
			background-position: 0 -200px;
		}

		@media (max-width:1365px) {
			background-position: 0 -157px;
		}

		@media (max-width:991px) {
			background-position: 0 -90px;
		}

		@media (max-width:767px) {
			background-position: 0 -24px;
		}

	}

	.img-align-center {

		text-align: center;
		margin: 0 auto;
		display: inherit;

		@media (min-width:1201px) {
			width: 70%;
		}
	}
}

.faq-v2 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner-faq.png);
	}
}