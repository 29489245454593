.search-main-form-v1 {

	.form-bx-area,
	.datepicker-trigger,
	.form-group {
		position: relative;

		.form-bx-area {
			background-color: var(--color-white);
		}

		.fa {
			position: absolute;
			right: 1rem;
			top: 17px;
			color: var(--color-grey-dark);
			font-size: 22px;
			display: block;
			line-height: 21px;

			@media (max-width: 992px) {
				font-size: 16px;
				top: 12px;
				right: 10px;
			}
		}

		.fa.fa-calendar-alt {
			@media (max-width: 574px) {
				top: 12px;
				bottom: unset;
			}
		}

	}


	.form-control {
		border-radius: 3px;
		font-size: 16px;
		cursor: pointer;
		background: transparent !important;
		position: relative;
		z-index: 1;
		boder: 1px solid #ced4da !important;
		padding-right: 35px !important;

		@media all and (min-width: 1200px) and (max-width: 1500px) {
			font-size: 13px !important;
		}

		@media (min-width:1200px) {
			font-size: 16px;
			padding: 14px 1rem;
			min-height: 55px;
			height: auto;
		}

		@media (max-width:1199px) {
			font-size: 13px;
			height: 55px;
		}

		@media (max-width:992px) {
			font-size: 15px;
			height: auto;
			min-height: 45px;
		}


		&.btn-lg {

			font-size: 16px;

			@media all and (min-width: 1200px) and (max-width: 1500px) {
				font-size: 13px !important;
			}

			@media (min-width: 993px) {
				font-size: 15px;
				padding: 0 1rem;
				min-height: 55px;
				height: auto;
			}


			@media (max-width:1199px) {
				font-size: 13px;
			}

			@media (max-width:992px) {
				font-size: 15px;
				height: auto;
				min-height: 45px;
			}

		}

	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}


}