.searchbar-v3 {
	position: absolute;
	top: 65vh;
	width: 100%;

	@media (max-width: 767px) {
		padding: 0px 10px;
		top: 50vh;
	}

	@media (max-height: 600px) {
		top: 350px;

	}

	.tab {
		//color: #c9c6c6;
		color: #000000;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 6px 20px;
		opacity: 1;
		cursor: pointer;
		display: inline-block;


		&.active {
			background-color: var(--color-primary);
			color: #fff;
		}

	}

	#search-obj-form {
		display: none;
	}

	.search-form-area {

		background-color: #fff;
		background-color: var(--color-white);
		border: 10px solid #eaeaea;
		border: 10px solid var(--color-grey-normal);
		border-radius: 8px;
		padding: 9px 15px 0;

		.search-text {
			background-color: var(--color-primary);
			margin: 5px -15px 0;
			font-weight: bold;
			padding: 5px 0;

			.btn {
				padding: 0;
				background: transparent;
				border: 0;
				color: #fff;
				font-weight: 700;
			}

			a {
				color: #000;
				display: inline-block;
				text-decoration: underline;
			}
		}

		.searchbox-obj {
			padding-top: 4px;
		}

		.form-obj-flex {
			width: 90%;
			padding: 9px 15px;

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}

			.fa {
				position: absolute;
				left: 10px;
				top: 11px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid #ddd;
				padding: 8px 10px 8px 30px;
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
				padding-right: 30px;
			}

			.form-flex-area {
				position: relative;
			}

		}

		.form-flex {
			width: 30%;
			padding: 5px 15px;
			border-right: 1px solid #f4f4f4;


			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}


			.fa {
				position: absolute;
				left: 10px;
				top: 11px;
			}

			#datepicker-trigger-flex {

				height: auto !important;
				padding-right: 30px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid #ddd;
				padding: 8px 10px 8px 30px;
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
				padding-right: 30px;
			}

			.form-flex-area {
				position: relative;
			}
		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
			}

			.btn {
				width: 101%;
				height: 50px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}